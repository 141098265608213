body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#gav {
  position: fixed;
  z-index: 0;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden !important;
  touch-action: none;
}

.root {
  z-index: 10000;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  position: absolute;
}

:root {
  --icon-size: calc((1.5vw + 2vh) / 2);
}

/* FLEX */

.flex-center-all {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex {
  display: flex;
}

.j-between {
  justify-content: space-between;
}

.j-around {
  justify-content: space-around;
}

.j-center {
  justify-content: center;
}

.a-center {
  align-items: center;
}

.j-start {
  justify-content: flex-start;
}

.a-start {
  align-items: flex-start;
}

.j-end {
  justify-content: flex-end;
}

.a-end {
  align-items: flex-end;
}

.f-column {
  flex-direction: column;
}

.f-row {
  flex-direction: row;
}

.f-column {
  flex-direction: column;
}

.f-column-reverse {
  flex-direction: column-reverse;
}

.f-wrap {
  flex-wrap: wrap;
}

.f-nowrap {
  flex-wrap: wrap;
}

/* ------------------------------------------------------------------------- */