/* PANNELLO ATTIVO */

.d-panel-main {
    width: calc(100vw - calc(2vw * 2));
    padding: 10vh 2vw;
    padding-top: 5vh;
    height: calc(100vh - (5vh + 10vh));
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
    position: absolute;
    z-index: 1201;
    /* top: 0; */
    top: 0vh;
    right: 0;
    /* background: linear-gradient(to top, rgba(179, 179, 179, 0.9), rgba(179, 179, 179, 0.9), transparent); */
    background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(226, 226, 226, 0.939), rgba(206, 206, 206, 0.964), rgba(206, 206, 206, 0.864), rgba(206, 206, 206, 0.664));
    /* box-shadow: 0px 0px 20px -14px rgba(0, 0, 0, 0.637); */
    transition: right 0.5s, top 0.5s, filter 0.6s;
    filter: blur(0px);
    pointer-events: all !important;
}

.d-panel-main-inactive {
    /* right: -33vw; */
    top: 100vh;
    filter: blur(5px) !important;
}

.d-panel-top-level-button {
    z-index: 10001 !important;
}

.d-panel-icon-container {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px !important;
}

.icon-label {
    font-family: 'Raleway';
    font-size: 0.8em;
    color: rgba(255, 255, 255, 0.803);
    letter-spacing: 0.4px;
    margin-bottom: -7px;
}

@media only screen and (max-width: 1009px) {
    .d-panel-main {
        padding-top: 5vh !important;
        padding-bottom: 5vh !important;
        min-height: calc(100vh - (5vh *2)) !important;
        max-height: calc(100vh - (5vh *2)) !important;
        overflow-y: scroll;
    }
    .d-panel-buttons-panel {
        width: 100%;
        height: 17vh;
        display: flex;
        justify-content: flex-start;
    }
    .d-panel-title {
        font-family: "Raleway";
        font-size: 2.4em !important;
        color: rgba(0, 0, 0, 0.6);
        margin: 20px 30px !important;
    }
    .d-panel-subdata-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 30px;
    }
    .d-panel-description {
        width: 95% !important;
        font-family: "Raleway";
        color: rgba(0, 0, 0, 0.7);
        font-weight: 400;
        font-size: 1.3em;
        margin: 8px 30px;
    }
    .d-panel-description-by {
        font-family: "Raleway";
        color: rgba(0, 0, 0, 0.55);
        font-size: 1.2em;
        margin: 30px 30px;
        margin-top: 30px !important;
        float: right;
    }
}

@media only screen and (max-width: 729px) {
    .d-panel-main {
        padding-top: 7vh !important;
        height: calc(100vh - (7vh + 10vh));
    }
    .d-panel-subdata-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .d-panel-buttons-panel {
        width: 100%;
        height: 8vh;
        display: flex;
        justify-content: flex-start;
    }
    .d-panel-main-inactive {
        /* right: -33vw; */
        bottom: -100vh;
        filter: blur(5px) !important;
    }
    .d-panel-title {
        font-family: 'Raleway';
        font-size: 2.2em !important;
        color: rgba(0, 0, 0, 0.6);
        margin: 22px 30px
    }
    .d-panel-description {
        width: 85% !important;
        color: rgba(0, 0, 0, 0.7);
        font-weight: 400;
        font-size: 1.3em;
        margin: 8px 30px !important
    }
}

.d-panel-title {
    font-size: 2.4em;
    color: rgba(0, 0, 0, 0.6);
    margin: 22px 30px;
    margin-top: 0px;
}

.d-panel-place {
    color: rgba(0, 0, 0, 0.7);
    font-size: 1.3em;
    margin: 10px 30px !important;
    text-align: center;
}

.d-panel-description {
    width: 65%;
    font-family: 'Raleway';
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
    font-size: 1.3em;
    margin: 40px 30px;
}

.d-panel-description-by {
    font-family: 'Raleway';
    color: rgba(0, 0, 0, 0.55);
    font-size: 1.2em;
    margin: 40px 30px;
}

.d-panel-buttons-panel {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.d-panel-close-icon, .d-panel-goto-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px !important;
    min-height: 40px !important;
    cursor: pointer;
    font-size: 1.6em;
    color: rgb(255, 255, 255);
    margin: 0px !important;
    padding: 5px !important;
    margin: 15px !important;
    border-radius: 50%;
    pointer-events: all !important;
    transition: color 0.3s, background-color 0.3s;
}

.d-panel-goto-icon {
    color: rgb(255, 255, 255) !important;
}

.d-panel-close-icon:hover, .d-panel-goto-icon:hover {
    background-color: rgba(135, 135, 135, 0.741);
}

.d-panel-close-icon:active, .d-panel-goto-icon:active {
    background-color: rgb(104, 104, 104);
    transition: color 0.05s, background-color 0.05s;
}