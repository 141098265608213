.splash-container {
    position: relative;
    width: 100%;
    min-width: 100%;
    height: fit-content;
    min-height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #e4e4e4;
    flex-direction: column;
    font-family: 'Nanum Myeongjo', serif;
    font-size: 1.2rem;
    z-index: 10000;
}

/* TOP LEFT */

.forarts-presenta {
    position: absolute;
    /* background-color: #aa223a; */
    background-size: contain;
    background-position: top left;
    background-repeat: no-repeat;
    image-rendering: optimizeSpeed !important;
    top: 0;
    left: 0;
    width: 40vw !important;
    height: 19.52vw !important;
    /* RAPPORTO: h = w * 0.488 */
}

/* BOTTOM RIGHT */

.forarts-footer {
    padding: 0 20px 15px 0;
    position: absolute;
    image-rendering: optimizeSpeed !important;
    /* background-color: #aa223a; */
    background-size: contain;
    background-position: bottom right;
    background-repeat: no-repeat;
    bottom: 0;
    right: 0;
    width: 40vw !important;
    height: 19.52vw !important;
    /* RAPPORTO: h = w * 0.488 */
}

/* TOP LEFT TEXT */

.splash-screen-forarts-text {
    position: relative;
    color: white;
    letter-spacing: 1px;
    margin-top: -5.5vw;
    margin-left: 5.5vw;
    font-size: 17vw;
    line-height: 50px;
}

.splash-gav-title {
    font-family: 'HNB' !important;
    text-align: center;
    padding: 0 10vw 3vh 10vw;
    width: calc(100% - (10vw * 2));
    /* line-height: 2em; */
    letter-spacing: 7px;
    font-size: 2.9em;
}

.loaders-container {
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    bottom: 0;
    /* transform: scale(0.5); */
    animation: logo-rotation linear infinite 2s;
}

.splash-loading-perc {
    position: absolute;
    margin: auto;
    height: 180px;
    width: 180px;
    text-align: center;
    font-family: 'HN';
}

.splash-loading-advice {
    margin-top: 30px;
    text-align: center;
    font-size: 1vw;
    color: #3c3c3c8c;
    font-family: 'Raleway';
}

.logo {
    position: absolute;
    width: 180px;
    height: 180px;
    transform-origin: center;
    /* animation: logo-rotation 3s infinite linear; */
    transition: transform 3s;
}

.loader {
    opacity: 0.75;
    width: 180px;
    height: 180px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    animation: loader-rotation 0.6s infinite step-end;
    transition: transform 0s;
}

.footer-diritti {
    margin-top: 50px;
    bottom: 50px;
    font-size: 1vw;
    letter-spacing: 1px;
    right: 50px;
    font-family: 'Dosis', sans-serif;
    color: #ffffffab;
}

.splash-screen-footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 101;
}

.splash-red-edge {
    position: absolute;
    z-index: 100;
    bottom: -15vw;
    right: -15vw;
    width: 30vw;
    height: 30vw;
    background-color: #620a1e;
    transform: rotate(45deg);
    box-shadow: -6px 0px 25px -16px black;
}

.splash-tutotrial-row {
    font-family: 'Raleway';
    font-weight: 400;
    color: #3c3c3cf3 !important;
    width: 45%;
    padding: 0 0 8vh 0;
    font-size: 0.6em;
}

.splash-tutorial-text {
    text-align: center;
    font-size: 1.75em;
    width: 100%;
}

.splash-tutorial-icon {
    font-size: 4em;
}

.splash-icon-container {
    margin-right: 10px;
    width: 15%;
}

@media only screen and (max-aspect-ratio: 16/7) {
    .splash-container {
        min-height: calc(100vh - (14vh + 20vh));
        padding: 20vh 0 14vh 0 !important;
    }
}

@media only screen and (max-aspect-ratio: 20/7) {
    .splash-container {
        min-height: calc(100vh - (35vh + 25vh));
        padding: 25vh 0 35vh 0 !important;
    }
    .splash-gav-title {
        padding: 0 10vw 4vh 10vw;
        color: #3c3c3cd7;
        font-family: 'HNB' !important;
        letter-spacing: 4px;
        font-size: 3.2vw !important;
    }
}

@media only screen and (max-width: 729px), only screen and (orientation: landscape) and (max-width: 729px) {
    .splash-container {
        position: relative;
        width: 100%;
        min-width: 100%;
        height: fit-content;
        min-height: 100vh;
        padding: 0vw 0 25vh 0;
        max-height: fit-content !important;
        overflow-y: auto !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: #e4e4e4;
        flex-direction: column;
        font-family: 'Nanum Myeongjo', serif;
        font-size: 1.2rem;
        z-index: 10000;
    }
    .splash-gav-title {
        padding: 1vh 10vw 7vh 10vw;
        color: #3c3c3cd7;
        font-family: 'HNB' !important;
        letter-spacing: 4px;
        font-size: 3.2vw !important;
    }
    .splash-tutotrial-row {
        font-family: 'Myeongjo';
        color: #3c3c3cc5 !important;
        width: 80%;
        /* margin-right: 10%; */
        padding: 0 0 8vh 0;
        font-size: 0.6em;
    }
    .splash-tutorial-text {
        text-align: center;
        font-size: 1.2em;
        width: 100%;
    }
    .splash-tutorial-icon {
        margin-right: 10px;
        font-size: 3em !important;
    }
    .splash-screen-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;

        position: fixed;
        top: 30px;
        left: unset;
        right: 0px !important;
        width: 100vw;
        z-index: 101;
    }
    .forarts-presenta {
        width: 100vw !important;
        height: 48.88vw !important;
        position: absolute;
        /* background-color: #aa223a; */
        background-size: contain;
        background-position: top left;
        background-repeat: no-repeat;
        top: 0;
        left: 0;
        /* RAPPORTO: h = w * 0.488 */
    }
    /* TOP LEFT TEXT */
    .splash-screen-forarts-text {
        position: relative;
        color: white;
        letter-spacing: 1px;
        margin-top: -6.5vw;
        margin-left: 2vw;
        font-size: 30vw;
        line-height: 50px;
    }
    /* BOTTOM RIGHT */
    .forarts-footer {
        width: 100vw !important;
        height: 48.88vw !important;
        padding: 0 20px 15px 0;
        position: absolute;
        /* background-color: #aa223a; */
        background-size: contain;
        background-position: bottom right;
        background-repeat: no-repeat;
        bottom: 0;
        right: 0;
        /* RAPPORTO: h = w * 0.488 */
    }
    .footer-diritti {
        position: relative !important;
        font-family: 'Raleway', sans-serif;
        font-size: 2.5vw !important;
        bottom: unset;
        left: unset;
        right: unset;
    }
    .loaders-container {
        width: 223px;
        height: 223px;
        position: relative;
    }
    .splash-loading-perc {
        position: absolute;
        margin: auto;
        height: 223px;
        width: 223px;
        text-align: center;
    }
    .logo {
        position: relative;
        bottom: unset;
        left: unset;
        right: unset;
        width: 192px;
        height: 192px;
    }
    .loader {
        width: 223px;
        height: 223px;
    }
    .splash-loading-advice {
        margin-top: 30px;
        text-align: center;
        font-size: 3vw;
        color: #3c3c3c8c;
        font-family: 'Raleway';
    }
}

/* TABLET AND MOBILE LANDSCAPE */

/* @media only screen and (orientation: landscape) and (max-height: 922px) {
    .splash-container {
        padding: 15vw 0 70vh 0 !important;
    }
} */

/* Rotation Animation */

@keyframes logo-rotation {
    from {
        transform: rotate(0deg) scale(0.65);
    }
    to {
        transform: rotate(360deg) scale(0.65);
    }
}

@keyframes loader-rotation {
    0% {
        transform: rotate(0deg) scale(1);
    }
    12.5% {
        transform: rotate(45deg) scale(1);
    }
    25% {
        transform: rotate(90deg) scale(1);
    }
    37.5% {
        transform: rotate(135deg) scale(1);
    }
    50% {
        transform: rotate(180deg) scale(1);
    }
    62.5% {
        transform: rotate(225deg) scale(1);
    }
    75% {
        transform: rotate(270deg) scale(1);
    }
    87.5% {
        transform: rotate(315deg) scale(1);
    }
    100% {
        transform: rotate(360deg) scale(1);
    }
}