.mainContainer {
    position: absolute;
    bottom: 0;
    height: calc(100vh - (2vh * 2));
    width: calc(15vw - (2vw * 2));
    padding: 2vh 2vw;
}

.icon {
    z-index: 1200 !important;
    cursor: pointer;
    color: rgba(232, 232, 232, 0.6);
    font-size: 1.4em;
    /* margin-right: 20px; */
    transform: rotate(90deg);
    border-radius: 50%;
    min-height: 50px;
    min-width: 50px;
    transition: background-color 0.3s, transform 0.3s;
    touch-action: all;
    pointer-events: all;
}

.iconNoRotation {
    transform: rotate(270deg) !important;
}

.icon:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

.icon:active {
    background-color: rgba(0, 0, 0, 0.8);
    transition: background-color 0.1s;
}

.hide {
    transform: translateY(70vh);
    transition: transform 0.9s;
}

.show {
    transform: translateX(0vh);
    transition: transform 0.4s;
}

/* HORIZONTAL */

@media only screen and (max-height: 500px) {
    .mainContainer {
        position: absolute;
        flex-direction: row;
        bottom: 0;
        height: calc(30vh - (0vh * 2)) !important;
        width: calc(100vw - (1.5vw * 2)) !important;
        padding: 0vh 1.5vw!important;
    }
    .show, .hide {
        flex-direction: row !important;
    }
    .icon {
        transform: rotate(180deg);
    }
    .iconNoRotation {
        transform: rotate(0deg) !important;
    }
    .hide {
        transform: translateX(-100vw);
        transition: transform 0.9s;
    }
    .show {
        transform: translateX(0vh);
        transition: transform 0.4s;
    }
}

/* SUPER WIDE DESKTOP */

/* { */

/* @media (min-aspect-ratio: 19/9) and (orientation: portrait) {
    .mainContainer {
        position: absolute;
        flex-direction: row;
        bottom: 0;
        padding: 2vh 2vw 15vh 2vw;
    }
    .icon {
        transform: rotate(180deg);
    }
    .iconNoRotation {
        transform: rotate(0deg) !important;
    }
    .hide {
        transform: translateX(-100vw);
        transition: transform 0.9s;
    }
    .show {
        transform: translateX(0vh);
        transition: transform 0.4s;
    }
}
 */

/* @media (min-aspect-ratio: 19/9) and (orientation: landscape) {
    .mainContainer {
        position: absolute;
        flex-direction: row;
        bottom: 0;
        height: calc(20vh - (2vh + 2vh)) !important;
        width: calc(100vw - (2vw * 2)) !important; 
        padding: 2vh 2vw 2vh 2vw !important;
    }
    .icon {
        transform: rotate(180deg);
    }
    .iconNoRotation {
        transform: rotate(0deg) !important;
    }
    .hide {
        transform: translateX(-100vw);
        transition: transform 0.9s;
    }
    .show {
        transform: translateX(0vh);
        transition: transform 0.4s;
    }
} */

/* } */

/* MEDIUM BIG DESKTOP */

/* { */

@media only screen and (max-width: 1800px) and (min-width: 1434px) {}

/* } */

/* MEDIUM DESKTOP */

/* { */

@media only screen and (max-width: 1433px) and (min-width: 1280px) {}

/* } */

/* SMALL PC */

/* { */

@media only screen and (max-width: 1280px) and (min-width: 991px) {}

/* } */

/* TABLET */

/* { */

/* @media only screen and (max-width: 991px) and (min-width: 729px) {
    .mainContainer {
        position: absolute;
        bottom: 0;
        padding: 2vh 2vw 2vh 2vw;
    } */

/* } */

/* } */

/* MOBILE VERTICAL */

/* { */

/* @media only screen and (max-width: 729px) and (orientation: portrait) {
    .mainContainer {
        position: absolute;
        bottom: 0;
        padding: 2vh 10vw 2vh 10vw;
    }
} */

/* } */

/* MOBILE HORIZONTAL */

/* { */

@media only screen and (max-width: 729px) and (orientation: landscape) {}

/* } */

/* SMALL MOBILE VERTICAL */

/* { */

@media only screen and (max-width: 412px) and (orientation: portrait) {
    .mainContainer {
        width: 20vw;
    }
}

/* } */