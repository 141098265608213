.container {
    position: relative;
    width: 96vw !important;
    padding: 0 3vw 3vw 3vw;
    min-width: 96vw;
    min-height: fit-content;
    max-height: 90vh;
    margin: 5vh 2vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #e4e4e4;
    flex-direction: column;
    font-size: 1.2rem;
    z-index: 10000;
    border-radius: 8px;
    box-shadow: 0px 0px 20px -12px rgba(0, 0, 0, 0.741);
    box-sizing: border-box;
    pointer-events: all !important;
}

.closeIcon {
    /* position: absolute; */
    padding: 25px;
    top: 0px;
    left: 0px;
    cursor: pointer;
}

.header {
    width: 100%;
    margin-bottom: 9vh;
}

.title {
    font-family: 'HNB' !important;
    text-align: left;
    padding: 0 4vw 0 4vw !important;
    /* margin-bottom: 8vh; */
    width: calc(100% - (4vw * 2));
    letter-spacing: 1px !important;
    font-size: 2.75em;
}

.logo {
    position: absolute;
    width: 180px;
    height: 180px;
    transform-origin: center;
    /* animation: logo-rotation 3s infinite linear; */
    transition: transform 3s;
}

.tipsContainer {
    width: 85%;
}

.splashTutotrialRow {
    font-family: 'Raleway';
    font-weight: 400;
    color: #3c3c3cf3 !important;
    width: 50%;
    padding: 0 20px 6vh 0;
    font-size: 0.6em;
    box-sizing: border-box;
}

.text {
    text-align: left;
    margin-left: 8px;
    font-size: 1.75em;
    letter-spacing: 0px !important;
    width: 100%;
}

.icon {
    font-size: 4em;
}

.iconContainer {
    margin-right: 10px;
    width: 15%;
    color: #620a1e;
}

.footer {
    width: 95%;
    padding: 2vh 0;
}

.footerIcon {
    font-size: 3em;
    opacity: 0.7;
    transition: opacity 0.18s;
    cursor: pointer;
}

.footerIcon:hover {
    opacity: 1;
}

@media only screen and (max-aspect-ratio: 16/7) {}

@media only screen and (max-aspect-ratio: 20/7) {
    /* .title {
        padding: 0 10vw 4vh 10vw;
        color: #3c3c3cd7;
        font-family: 'HNB' !important;
        letter-spacing: 4px;
        font-size: 3.2vw !important;
    } */
}

@media only screen and (max-width: 1000px), only screen and (orientation: landscape) and (max-width: 1000px) {
    .tipsContainer {
        width: 95%;
    }
    .text {
        font-size: 1.4em;
    }
}

@media only screen and (max-width: 729px), only screen and (orientation: landscape) and (max-width: 729px) {
    .container {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: #e4e4e4;
        flex-direction: column;
        font-family: 'Nanum Myeongjo', serif;
        font-size: 1.2rem;
        z-index: 10000;
        margin: 2vh 2vw;
        max-height: 96vh;
    }
    .header {
        margin-top: 2vh;
        margin-bottom: 4.5vh;
    }
    .title {
        padding: 0vh 2vw 0vh 2vw !important;
        color: #3c3c3cd7;
        font-family: 'HNB' !important;
        letter-spacing: 4px;
        font-size: 5.8vw !important;
    }
    .tipsContainer {
        width: 85%;
    }
    .splashTutotrialRow {
        font-family: 'Myeongjo';
        color: #3c3c3cc5 !important;
        width: 100%;
        /* margin-right: 10%; */
        padding: 0 0 4vh 0;
        font-size: 0.6em;
    }
    .text {
        font-size: 1.25em;
        width: 100%;
    }
    .icon {
        margin-right: 10px;
        font-size: 3em !important;
    }
    .footerIcon {
        font-size: 2em;
    }
}