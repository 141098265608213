.container {
    z-index: 1200;
    position: relative;
    width: 60px;
    height: 60px;
    margin: 15px;
    cursor: pointer;
    pointer-events: all !important;
}

/* STYLES */

/* Default */

.default {
    border-radius: 50%;
    /* border: solid 0.3px rgba(230, 230, 230, 0.426); */
    background-color: #3c3c3c7c;
    box-shadow: 0px 0px 23px -17px black;
    transition: background-color 0.2s, color 0.2s;
}

.default:hover {
    background-color: #3c3c3c9a;
}

.default:active {
    background-color: #3c3c3c56;
}

.subContainer {
    width: 100%;
    height: 100%;
    color: #c4c4c4;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    font-size: calc(var(--icon-size) - 0.4em);
    color: #d3d3d3;
    transition: transform 0.09s ease-in-out;
    transform-origin: center;
}

.bigIcon {
    font-size: calc(var(--icon-size) + 1em) !important;
    line-height: 30px !important;
    transition: transform 0.09s ease-in-out;
    transform-origin: center;
}

.default:hover .bigIcon, .default:hover .icon {
    transform: scale(1.1);
}

.label {
    position: absolute;
    margin-left: 30px;
    left: 0;
    white-space: nowrap;
    font-family: 'Raleway';
    text-align: center;
    /* width: calc(100% - (50px * 2)); */
    font-weight: 700;
    padding: 50px;
    font-size: calc(var(--icon-size) - 0.5em) !important;
    color: #1c1c1c;
    background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.1) 0%, transparent 50%);
    transition: background-color 0.2s, color 0.2s;
}

.default:hover>.label {
    color: black;
}

/* 
@media only screen and (max-width: 729px) {
    .container {
        font-size: 3em;
        width: 50px;
        margin: 12px;
        height: 50px;
    }
    .icon {
        font-size: calc(var(--icon-size)) !important;
        color: #d3d3d3;
    }
} */

/* HORIZONTAL */

@media only screen and (max-height: 500px) {
    .bigIcon {
        font-size: calc(var(--icon-size) + 1.2em) !important;
    }
    .icon {
        font-size: calc(var(--icon-size) + 0.5em);
    }
    .label {
        margin-right: auto !important;
        margin-left: -50% !important;
        white-space: wrap;
        left: 0;
        top: -30px;
        font-size: calc(var(--icon-size) + 0.03em) !important;
        width: calc(200% - (10px * 2));
        padding: 10px;
    }
}

/* VERTICAL */

@media only screen and (max-aspect-ratio: 1) {}

/* MOBILE VERTICAL */

/* { */

@media only screen and (max-width: 729px) {
    .container {
        font-size: 5em !important;
        width: 50px;
        margin: 12px;
        height: 50px;
    }
    .bigIcon {
        font-size: calc(var(--icon-size) + 0.2em) !important;
    }
    .icon {
        font-size: calc(var(--icon-size) + 0.1em);
    }
    .label {
        margin-left: 12px;
        font-size: calc(var(--icon-size) + 0.03em) !important;
    }
}

/* } */

/* @media only screen and (max-height: 729px) and (orientation:landscape) {
    .container {
        font-size: 4.7em !important;
        width: 50px;
        margin: 12px;
        height: 50px;
    }
    .icon {
        font-size: calc(var(--icon-size) + 0.1em) !important;
    }
} */