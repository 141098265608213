.container {
    position: absolute;
    width: calc(100% - (5% * 2));
    padding: 5vh 5%;
    height: calc(100vh - (5vh * 2));
    min-height: calc(100vh - (5vh * 2));
    max-height: calc(100vh - (5vh * 2));
    z-index: 9000;
    background-color: rgba(0, 0, 0, 0.83);
    pointer-events: all;
}

.iconContainer {
    position: absolute;
    left: 6.5vw;
    color: rgb(255, 255, 255) !important;
}

/* .iconContainer:hover {
    background-color: rgba(135, 135, 135, 0.741);
}

.iconContainer:active {
    background-color: rgb(104, 104, 104);
    transition: color 0.05s, background-color 0.05s;
} */

.imageContainer {
    height: 100%;
}

.image {
    /* width: 70%; */
    height: 100%;
    box-shadow: 5px 5px 40px -13px rgba(0, 0, 0, 0.788);
}

.icon {
    color: grey;
}

@media only screen and (max-width: 729px) {
    .imageContainer {
        height: 100% !important;
        width: 100%;
    }
    .image {
        width: 100%;
        height: auto !important;
        box-shadow: 5px 5px 40px -13px rgba(0, 0, 0, 0.788);
    }
}